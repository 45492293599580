var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{attrs:{"id":"wrap","type":"flex"}},[_c('a-col',{attrs:{"flex":7,"id":"background"}}),_c('a-col',{attrs:{"flex":2}},[_c('a-form',{staticClass:"register-form",attrs:{"id":"components-form-demo-normal-register","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"Номер телефона"}},[_c('InputMask',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'Phone',
            { rules: [{ required: true, message: 'Пожалуйста заполните номер телефона' }] } ]),expression:"[\n            'Phone',\n            { rules: [{ required: true, message: 'Пожалуйста заполните номер телефона' }] },\n          ]"}],staticClass:"ant-input",attrs:{"mask":['### (##) ###-##-##'],"placeholder":"XXX (XX) XXX-XX-XX"},on:{"input":_vm.handleChange}})],1),_c('a-form-item',{attrs:{"label":"Имя"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'FirstName',
            { rules: [{ required: true, message: 'Имя объязательно для заполнение' }] } ]),expression:"[\n            'FirstName',\n            { rules: [{ required: true, message: 'Имя объязательно для заполнение' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Фамилия"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'LastName',
            { rules: [{ required: true, message: 'Фамилия объязательно для заполнение' }] } ]),expression:"[\n            'LastName',\n            { rules: [{ required: true, message: 'Фамилия объязательно для заполнение' }] },\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"Электронная почта"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Email',
        { rules: [{ required: false, message: 'Пожалуйста заполните Электронная почту' }] } ]),expression:"[\n        'Email',\n        { rules: [{ required: false, message: 'Пожалуйста заполните Электронная почту' }] },\n      ]"}]})],1),_c('a-form-item',{attrs:{"label":"Пароль"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'Password',
        { rules: [{ required: true, message: 'Пожалуйста заполните Пароль' }] } ]),expression:"[\n        'Password',\n        { rules: [{ required: true, message: 'Пожалуйста заполните Пароль' }] },\n      ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":"Потдвердите пароль"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'Password_confirmation',
            { rules: [{ required: true, message: 'Потдвердите пароль' }] } ]),expression:"[\n            'Password_confirmation',\n            { rules: [{ required: true, message: 'Потдвердите пароль' }] },\n            ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"register-form-button",attrs:{"type":"danger","html-type":"submit","loading":_vm.isSubmitting}},[(!_vm.isSubmitting)?_c('span',[_vm._v("Зарегистрироватся")]):_vm._e()]),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("Уже есть аккаунт?")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }