<template>
  <SecondaryLayout>
    <Register/>
  </SecondaryLayout>
</template>

<script>
import Register from '../modules/auth/Register.vue';
import SecondaryLayout from '../layouts/Secondary.vue';

export default {
  components: {
    SecondaryLayout,
    Register,
  },
};
</script>
